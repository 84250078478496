import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConfig } from '../../config/appconfig';

const initialState: AppConfig = {
  apiUri: '',
  appUri: '',
  oidcAuthority: '',
  translationApiUri: '',
  appVersion: '',
  requestTimeout: 0
};

export const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<AppConfig>) => action.payload
  }
});

export const { setConfig } = slice.actions;

export default slice.reducer;