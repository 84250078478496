import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Validation } from './validation';

const initialState: Validation = {
  isValidated: true,
  validationMessage: ''
};

export const slice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    setValidation: (state, action: PayloadAction<Validation>) => action.payload
  }
});

export const { setValidation } = slice.actions;

export default slice.reducer;