import { useMemo } from 'react';
import { httpclient } from 'typescript-http-client';
import { useAccessToken, AuthorizationHttpFilter } from './authorization';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useConfig } from '../config/appconfig';

export function useApi(): httpclient.HttpClient {
  const token = useAccessToken();
  const appConfig = useConfig();

  return useMemo(() => {
    const client = httpclient.newHttpClient();

    client.addFilter(
      {
        doFilter: (request, chain: httpclient.FilterChain<any>) => {

          request.contentType = 'application/json';
          request.responseType = 'json';

          const url = request.url;

          if (!url.startsWith('http')) {
            request.url = appConfig.apiUri + url;
          }

          return chain.doFilter(request);
        }
      },
      'Base URI');

    client.addFilter(
      new AuthorizationHttpFilter(token),
      'Bearer token');

    return client;
  }, [token, appConfig.apiUri]);
}